<template>
	<div>
		<top :bgWhite='true' ></top>
		<div class=" contentAll  padding-tb-15 top-80  padding-lr-60">
			<el-row :gutter="24">
				<el-col :span="18">
					<div class="margin-bottom-30 flex align-center title_box">
						<i class="el-icon-arrow-left fs28" @click="backPage"></i>
						<p class="bold fs24 text-333 title">乡村人才库</p>
						<p class="bold fs24 text-333"></p>
					</div>
					<div class="left-info">
						<p class="name margin-right-5">{{userData.user_nickname}}</p>
						<div class="writer-tag flex align-center margin-tb-10" v-if="userData.tags_info">
							<p type="warning" size="mini" v-if="j<2" class="margin-right-5" v-for="(ele,j) in userData.tags_info" :key="j">{{ele}}</p>
						</div>
						<div class="">
							<span class="fs12 text-666 padding-right-100">{{userData.autograph ||'这个人很神秘，什么都没留下'}}</span>
						</div>
					</div>
					<!-- 代表作品 -->
					<div class="margin-top-30">
						<p class="bold fs20 product_title">代表作品</p>
					</div>
					<div class="flex align-center padding-tb-4 padding-tb-20 solid-bottom" >
						<div class="padding-lr-20" v-for="(item,index) in tabList" :key='index'>
							<span class="text-666 point fs18" @click="tabItem(item,index)" :class="tabIndex==index?'active':''">{{item.name}}</span>
						</div>
					</div>
					<div class="">
						<!-- 加载中 -->
						<div class="w100" v-if="!isInit">
							<el-skeleton :rows="12" animated />
						</div>
						<!-- 加载有数据 -->
						<div class=""v-else>
							<div v-if="lists.length>0">
								<!-- 文章 -->
								<div class="" v-if=" tabIndex==0">
									<div  v-for="(item,index) in lists" :key="index">
										<article-simple  :item="item" :current="2"></article-simple>
									</div>
								</div>
								<!--电子书 -->
								<el-row :gutter="24" v-if="tabIndex==1">
									<el-col :span="24" v-for="(item,index) in lists" :key="index">
										<div class="grid-content bg-purple">
											<book-simple :item="item"></book-simple>
										</div>
									</el-col>
								</el-row>
							</div>
							<div class="" v-if="lists.length==0">
								<el-empty description="~空空如也~"></el-empty>
							</div>
							<!-- 分页 -->
							<div class="flex align-center justify-center">
								<el-pagination background  layout="prev, pager, next" :total="totalSize" :page-size='15' :hide-on-single-page='true' @current-change="currentChange">
								</el-pagination>
							</div>
							<!-- 分页 end-->
						</div>
					</div>
				</el-col>
				<!-- 右侧信息 -->
				<el-col :span="6">
				<!-- 轮播图 -->
					<div class="swiper-container">
						<div class="swiper-wrapper">
							<div class="swiper-slide" v-for="(item,index) in userData.photo" :key="index">
								<el-image :src="item" class="auth-img" fit="cover"></el-image>
							</div>
						</div>
						<!-- 如果需要导航按钮 -->
					<!-- 	<div class="swiper-button-prev"></div>
						<div class="swiper-button-next"></div> -->
						<!-- 如果需要滚动条 -->
						<div class="swiper-scrollbar"></div>
					</div>
					<!-- 轮播图 -->
					<div class="auth_info padding-tb-10 margin-top-30">
						<p class="fs14 text-333 bold">基本信息</p>
						<div class="margin-top-20 margin-bottom-20">
							<el-row :gutter="24">
								<el-col :span="10">
									<span class="text-333 bold fs12 ">作家名称</span>
								</el-col>
								<el-col :span="14">
									<span class="fs12 text-999">{{userData.user_nickname  || '保密'}}</span>
								</el-col>
							</el-row>
						</div>
						<div class="margin-bottom-20">
							<el-row :gutter="24">
								<el-col :span="10">
									<span class="text-333 bold fs12 ">国籍</span>
								</el-col>
								<el-col :span="14">
									<span class="fs12 text-999">{{userData.citizenship || '保密'}}</span>
								</el-col>
							</el-row>
						</div>
						<div class="margin-bottom-20" v-if="userData.sex!=0">
							<el-row :gutter="24">
								<el-col :span="10">
									<span class="text-333 bold fs12 ">性别</span>
								</el-col>
								<el-col :span="14">
									<span class="fs12 text-999">{{userData.sex==0 ? '保密': userData.sex==1 ? '男' :'女'}}</span>
								</el-col>
							</el-row>
						</div>
						<div class="margin-bottom-20">
							<el-row :gutter="24">
								<el-col :span="10">
									<span class="text-333 bold fs12">出生日期</span>
								</el-col>
								<el-col :span="14">
									<span class="fs12 text-999">{{userData.birthday || '保密'}}</span>
								</el-col>
							</el-row>
						</div>
						<div class="margin-bottom-20">
							<el-row :gutter="24">
								<el-col :span="10">
									<span class="text-333 bold fs12 ">籍贯</span>
								</el-col>
								<el-col :span="14">
									<span class="fs12 text-999">{{userData.native_place || '保密'}}</span>
								</el-col>
							</el-row>
						</div>
						<div class="">
							<el-row :gutter="24">
								<el-col :span="10">
									<span class="text-333 bold fs12 ">民族</span>
								</el-col>
								<el-col :span="14">
									<span class="fs12 text-999">{{userData.nationality || '保密'}}</span>
								</el-col>
							</el-row>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<bottom :bgWhite='true'></bottom>
	</div>
</template>

<script>
	// 局部引入swiper,如果在main.js中引入过,以下三行可不写	
	import Swiper from 'swiper';
	import 'swiper/dist/css/swiper.min.css';
	import 'swiper/dist/js/swiper.min.js';
	// --------------------E-ND--------------
	import top from '@/components/top';
	import bottom from '@/components/bottom';
	import articleSimple from '@/components/dynamic/article-simple.vue';
	import bookSimple from '@/components/book-list/book-simple';
	export default {
		components: {
			bottom,
			top,
			articleSimple,
			bookSimple
		},
		data(){
			return{
				isInit:false, //是否加载完毕
				totalSize:'', //总页数
				user_id:'', //作者id
				userData:{}, //作者信息
				tabIndex:0,
				tabList: [{
					name: '文章',
					id: 1,
				}, {
					name: '书籍',
					id: 2,
				}],
				lists: []
			}
		},
		mounted() {
			this.user_id = this.$route.query.user_id
			this.getUserDetail();
			this.loadData();
			setTimeout(()=>{
				new Swiper('.swiper-container', {
					loop: true, //无缝轮播
					// 如果需要分页器
					// pagination: '.swiper-pagination',
					// 如果需要前进后退按钮
					nextButton: '.swiper-button-next',
					prevButton: '.swiper-button-prev',
					mousewheelControl: true, //开启鼠标滚轮控制
					// 如果需要滚动条
					scrollbar: '.swiper-scrollbar',
					//如果需要自动切换海报
					// autoplay: {
					//   delay: 3000,//时间 毫秒
					//   disableOnInteraction: true,//用户操作之后是否停止自动轮播默认true
					// },
				});
			},1000)
			
		},
		methods:{
			backPage(){
				this.$router.go(-1)
			},
			// tab切换
			tabItem(item, index) {
				if(this.tabIndex == index) return;
				this.isInit = false;
				this.lists = [];
				this.tabIndex = index;
				this.loadData()
			},
			// 翻页
			currentChange(e){
				console.log(e);
				this.page = e;
				this.loadData()
			},
			// 获取作者信息
			getUserDetail() {
				this.ajax('post', '/v1/5c78c4772da97', {
					user_id:this.user_id
				}, res => {
					if (res.code == 1) {
						this.userData = res.data
					} else {
						this.$message.error(res.msg);
					}
				
				}, err => {
					console.log(err);
				})
				
			},
			// 获取作家作品
			loadData() {
				this.ajax('post', '/v1/613b0f6c07cc9', {
					article_type:this.tabIndex+2, //1：动态2：文章3：电子书
					index_type:'' ,//1：关注2：推荐3：热榜
					page:this.page,
					user_id:this.user_id,
					is_representative:1
				}, res => {
					this.isInit = true;
					if (res.code == 1) {
						this.totalSize = res.data.total;
						this.lists =  res.data.data;
					} else {
						this.$message.error(res.msg);
					}
				
				}, err => {
					console.log(err);
					this.hadGotCode = false
				})
				
			}
		}
	}
</script>

<style  lang="scss">
html, body{
	background: #FFFFFF !important;
}
/* 轮播图 */
.swiper-container {
	height: 344px;
	width: 100%;
}
.swiper-wrapper .swiper-slide {
	width: 100%;
	height: 100%;
	// background-color: #42b983;
	text-align: center;
	line-height: 344px;
}
.swiper-button-next{
	
}
/* 轮播图 */

.active {
	position: relative;
	color: #333;
	font-weight: bold;
	font-size: 20px;
}
.contentAll{
	width: 68%;;
}
.writer-tag {
	p {
		width: 66px;
		height: 20px;
		text-align: center;
		opacity: 1;
		font-size: 8px;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 20px;
		color: #FFFFFF;
		opacity: 1;
		&:nth-child(1){
			background: url(../../assets/image/icon/label-bg1.png) no-repeat;
			background-size: cover;
		}
		&:nth-child(2){
			background: url(../../assets/image/icon/label-bg2.png) no-repeat;
			background-size: cover;
		}
	}
}
.active::after {
	position: absolute;
	content: '';
	bottom: -15px;
	left: 0;
	right: 0;
	width: 40%;
	margin: auto;
	background-color: #E54F42;
	height: 4px;
	border-radius: 5px;
}
.title_box{
	position: relative;
	.title{
		position: absolute;
		left: 50%;
	}
}
.product_title{
	position:relative;
	z-index:99;
	&:after{
		content: '';
		position:absolute;
		left:0;
		bottom:0;
		width: 75px;
		height: 7px;
		background: linear-gradient(89deg, rgba(229, 79, 66, 0.7) 0%, rgba(255, 255, 255, 0) 100%);
		opacity: 1;
		z-index:2
	}
}
.auth-img{
	width: 100%;
	height: 344px;
}
.auth_info{
	padding:20px 10px;
	min-height: 180px;
	background: #F6F7F9;
	opacity: 1;
}
</style>
<template>
	<div class="">
		<div class="list-items">
			<div class="point " @click='toDetail()' style="overflow: auto;">
				<div class="inner-content margin-tb-xs">
					<div class="flex align-center" v-if="current==2">
						<!-- <div class="ftag fs12 margin-right-10" :class="item.is_free==1 ? 'free_tag' :'fee_tag' ">
							{{item.is_free==1 ? '免费' : '收费'}}
						</div> -->
						<p class="bold fs16 margin-tb-20 text-333">{{item.title}}</p>
					</div>
					<p class="text-666" style="word-break: break-all;" v-if="current==1">{{item.body}}</p>
				</div>
				<!-- <el-row :gutter="24" @click='toDetail()' v-if="item && item.images">
					<el-col :span="8" v-for="(img_item,img_index) in item.images" :key="img_index">
						<div class="grid-content bg-purple">
							<el-image :src="img_item" fit="cover" :preview-src-list="item.images"></el-image>
						</div>
					</el-col>
				</el-row> -->
				<el-row :gutter="24" v-if="item && item.images && item.is_video==0">
					<el-col :span="8" v-for="(img_item,img_index) in item.images" :key="img_index">
						<el-image :src="img_item" fit="cover" class="w100 height148 radius-6" :preview-src-list="item.images"></el-image>
					</el-col>
				</el-row>
				<el-row :gutter="24" v-if="item && item.images && item.is_video==1">
					<el-col :span="8" v-for="(img_item,img_index) in item.images" :key="img_index">
						<video :src="img_item" controls="controls" width="400" height="">
							当前浏览器不支持 video直接播放，点击这里下载视频： <a :href="img_item">下载视频</a>
						</video>
					</el-col>
				</el-row>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			item:{
				type:Object,
				default:{}
			},
			current: [Number, String]
		},
		data() {
			return {
				lists: []
			}
		},
		methods: {
			toDetail() {
				// 帖子详情
				this.toBlank({
					path: '/artileDetail',
					query: {
						article_id: this.item && this.item.aid,
						atype: this.current
					}
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.title {
		height: 21px;
		font-size: 16px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		line-height: 21px;
		color: #333333;
		opacity: 1;
	}
	.ftag {
		width: 36px;
		text-align: center;
		height: 20px;
		line-height: 20px;
		border-radius: 0 8px;
		font-weight: bold;
	}
	
	.free_tag {
		color: #31CBA4;
		background: #CAF4D7;
	}
	
	.fee_tag {
		color: #FF7F37;
		background: #FDEDEB;
	}
	.list-items {
		border-bottom: 1px solid #EBEEF5;
	}

	.grid-content {
		img {
			width: 100%;
			;
		}
	}

	.writer-info {
		.head-img {
			width: 48px;
			height: 48px;
		}

		.left-info {
			.name {
				font-size: 11px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #333333;
				opacity: 1;
			}
		}

		.writer-tag {
			font-size: 12px;
		}
	}

	.inner-content {
		p {
			font-size: 9px;
		}
	}

	.time {
		font-size: 8px;
		font-family: PingFang SC;
		font-weight: 400;
		line-height: 11px;
		color: #999999;
		opacity: 1;
	}
</style>

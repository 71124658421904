<template>
	<div class="margin-top-20">
		<div class="list-items flex margin-bottom " @click="toDetail">
			<div class="book-cover margin-right-sm">
				<img :src="item.thumb | filterArray">
				<div class="ftag fs12" :class="item.is_free==1 ? 'free_tag' :'fee_tag' ">
					{{item.is_free==1 ? '免费' : '收费'}}
				</div>
			</div>
			<div class="right-info flex flex-direction justify-between w100">
				<div class="flex  justify-between">
					<div class=" flex flex-direction" style="height: 100%;">
						<div class="flex align-center title-box">
							<p class="book-title text-cut">{{item.title}}</p>
							<div class="ftag fs12" :class="item.is_continued==1 ? 'free_tag' :'fee_tag' ">
								{{item.is_continued==1 ? '连载' : '完结'}}
							</div>
						</div>
						<div class="flex align-center margin-top-10">
							<img src="../../assets/image/icon/push.png" class="block-18 margin-right-10">
							<p class="fs12">发布时间：{{item.create_time}}</p>
						</div>
					</div>
				</div>
				<!-- 按钮组 -->
				<div class="flex flex-direction handle-btn align-end w100 text-right">
					<el-button type="danger" plain round @click.stop="toCatalog">全部目录</el-button>
					<el-button type="danger"  round class="margin-top-20">开始阅读</el-button>
				</div>
				
				<!-- 内容概述 -->
				<div class="">
					<p class="fs9">内容概述</p>
					<p class="fs9 text-666 margin-top-5">{{item.body}}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				lists: []
			}
		},
		props:{
			type: [Number, String],
			showCollect:{
				type:Boolean,
				default:true
			},
			item:{
				type:Object,
				default:{}
			}
		},
		filters:{
			filterArray(val){
				return val[0]
			}
		},
		methods:{
			toDetail(){
				this.toBlank({
					path:'/fictionDetail',
					query:{
						aid:this.item && this.item.aid
					}
				})
				// this.$router.push({
				// 	path:'/fictionDetail',
				// 	query:{
				// 		aid:this.item && this.item.aid
				// 	}
				// })
			},
			// 跳转章节目录
			toCatalog(){
				console.log(this.item.aid);
				this.$router.push({
					path:'/catalog',
					query:{
						aid:this.item && this.item.aid
					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.book-cover {
		position: relative;
		width: 172px;
		height: 242px;
		background: rgba(0, 0, 0, 0);
		opacity: 1;
		border-radius: 8px 0px 0px 8px;
		flex-shrink: 0;
		img {
			width: 100%;
			height: 100%;
			border-radius: 8px 0px 0px 8px;
		}
		.ftag {
			position: absolute;
			top: 0;
			right: 0;
			width: 36px;
			text-align: center;
			height: 20px;
			line-height: 20px;
			border-radius: 0 0 0 10px;
			font-weight: bold;
		}
		
		.free_tag {
			color: #31CBA4;
			background: #CAF4D7;
		}
		
		.fee_tag {
			color: #FF7F37;
			background: #FDEDEB;
		}
	}

	.right-info {
		height: 242px;
		.title-box{
			.ftag {
				margin-left: 10px;
				width: 36px;
				text-align: center;
				height: 20px;
				line-height: 20px;
				border-radius: 0 0 0 10px;
				font-weight: bold;
			}
			
			.free_tag {
				color: #31CBA4;
				background: #CAF4D7;
			}
			
			.fee_tag {
				color: #FF7F37;
				background: #FDEDEB;
			}
		}
	}

	.book-title {
		font-size: 18px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #333333;
		opacity: 1;
	}

	.writer-info {
		.head-img {
			width: 29px;
			height: 29px;
		}

		.left-info {
			.name {
				font-size: 11px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #333333;
				opacity: 1;
			}
		}

		.writer-tag {
			p {
				width: 37px;
				height: 12px;
				background: linear-gradient(270deg, #FFA464 0%, #FF762C 100%);
				opacity: 1;
				font-size: 8px;
				font-family: PingFang SC;
				font-weight: 400;
				line-height: 12px;
				color: #FFFFFF;
				opacity: 1;
			}
		}
	}
	.handle-btn{
		.el-button{
			width: 148px;
			height: 40px;
			line-height: 5px;
			border: 1px solid #E54F42;
			opacity: 1;
			border-radius: 20px;
			font-size: 12px;
		}
		.el-button+.el-button{
			margin-left: 0;
		}
	}
</style>
